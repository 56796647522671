import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Box from "@mui/system/Box";
import _ from "@lodash";
import axios from "axios";
import { getAgency, selectAgency } from "../store/agencySlice";
import JwtService from "../../../../auth/services/jwtService/jwtService.js";
import BlockIcon from "@mui/icons-material/Block";
import { AgencyAccount } from "../store/agencySlice";
import { getAgencies } from "../store/agenciesSlice";
import { useNavigate } from "react-router-dom";
import { selectUser } from "app/store/userSlice";
import { Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";

const AgencyView = () => {
  const agency = useSelector(selectAgency);
  const user = useSelector(selectUser);
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var agencydata = {};

  async function handleClick(val) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/auth/impersonate`,
      { email: val }
    );
    if (response.data) {
      if (user?.role == "provider-admin") {
        window.localStorage.setItem("provider-impersonate", true);
        JwtService.setSession(response.data.access_token, true);
        window.location.href = "/dashboards/agency";
      } else {
        JwtService.setSession(response.data.access_token, true);
        window.location.href = "/dashboards/agency";
      }
    }
  }

  useEffect(() => {
    dispatch(getAgency(routeParams?.id));
  }, [dispatch, routeParams]);

  if (agency) {
    agencydata = agency;
  } else {
    agencydata = null;
  }

  if (!agency) {
    return <FuseLoading />;
  }

  const handleBlockAccount = async (agencyId, statusAccount) => {
    let agencyDataAccount = {
      id: agencyId,
      status: statusAccount,
    };
    dispatch(AgencyAccount(agencyDataAccount)).then(() => {
      navigate("/apps/agencies");
      dispatch(getAgencies());
    });
  };

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default",
        }}
      ></Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <div className="flex items-center ml-auto mb-4">
              {agencydata?.users ? (
                <>
                  {agencydata?.isActive == 1 ? (
                    <Button
                      className="mx-8"
                      variant="contained"
                      color="error"
                      onClick={() =>
                        handleBlockAccount(agencydata?.agency_id, "block")
                      }
                    >
                      <BlockIcon size={20} />
                      <span className="mx-8">Block Account</span>
                    </Button>
                  ) : (
                    <Button
                      className="mx-8"
                      variant="contained"
                      color="success"
                      onClick={() =>
                        handleBlockAccount(agencydata?.agency_id, "active")
                      }
                    >
                      <FuseSvgIcon size={20}>heroicons-outline:eye</FuseSvgIcon>
                      <span className="mx-8">Active Account</span>
                    </Button>
                  )}

                  <Button
                    disabled={agencydata?.isActive == 0 ? true : false}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClick(agencydata?.users?.email)}
                  >
                    <FuseSvgIcon size={20}>heroicons-outline:eye</FuseSvgIcon>
                    <span className="mx-8">Impersonate</span>
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="flex items-center ml-10 mb-4">
              <Button
                disabled={agencydata?.isActive == 0 ? true : false}
                variant="contained"
                color="secondary"
                component={NavLinkAdapter}
                to="edit"
              >
                <FuseSvgIcon size={20}>
                  heroicons-outline:pencil-alt
                </FuseSvgIcon>
                <span className="mx-8">Edit</span>
              </Button>
            </div>
          </div>
          {agencydata?.users ? (
            <Typography className="mt-32 text-4xl font-bold truncate">
              {agencydata?.users?.username}
            </Typography>
          ) : (
            ""
          )}
          {/* <Divider className="mt-16 mb-24" /> */}
          <div className="flex flex-col space-y-20">
            <Divider textAlign="left" className="w-full mt-32">
              <Chip label="Agency Information for Admin Account Creation" />
            </Divider>
            <div className="flex">
              <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
              <div className="min-w-0 ml-24 space-y-4">
                <div className="flex items-center leading-6">
                  {agencydata?.provider?.name}
                  <>
                    <Typography
                      className="text-md truncate"
                      color="text.secondary"
                    >
                      <span className="mx-8">&bull;</span>
                      <span className="font-medium">Provider Name</span>
                    </Typography>
                  </>
                </div>
              </div>
            </div>
            <div className="flex">
              <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
              <div className="min-w-0 ml-24 space-y-4">
                <div className="flex items-center leading-6">
                  {agencydata?.agency_name}
                  <>
                    <Typography
                      className="text-md truncate"
                      color="text.secondary"
                    >
                      <span className="mx-8">&bull;</span>
                      <span className="font-medium">Key</span>
                    </Typography>
                  </>
                </div>
              </div>
            </div>
            <div className="flex">
              <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
              <div className="min-w-0 ml-24 space-y-4">
                <div className="flex items-center leading-6">
                  {agencydata?.firstname}
                  <>
                    <Typography
                      className="text-md truncate"
                      color="text.secondary"
                    >
                      <span className="mx-8">&bull;</span>
                      <span className="font-medium">First Name</span>
                    </Typography>
                  </>
                </div>
              </div>
            </div>
            <div className="flex">
              <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
              <div className="min-w-0 ml-24 space-y-4">
                <div className="flex items-center leading-6">
                  {agencydata?.lastname}
                  <>
                    <Typography
                      className="text-md truncate"
                      color="text.secondary"
                    >
                      <span className="mx-8">&bull;</span>
                      <span className="font-medium">Last Name</span>
                    </Typography>
                  </>
                </div>
              </div>
            </div>

            {agencydata?.users ? (
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div className="flex items-center leading-6">
                    {agencydata?.users?.email}
                    <>
                      <Typography
                        className="text-md truncate"
                        color="text.secondary"
                      >
                        <span className="mx-8">&bull;</span>
                        <span className="font-medium">Email</span>
                      </Typography>
                    </>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {agencydata?.permission ? (
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div className="flex flex-wrap items-center leading-6">
                    {JSON.parse(agencydata?.permission)?.map((val) => (
                      <Chip
                        color="primary"
                        key={val}
                        label={val}
                        variant="outlined"
                        className="m-4"
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Divider textAlign="left" className="w-full mt-32">
              <Chip label="Agency API Key for Player Fetch" />
            </Divider>
            <div className="flex">
              <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
              <div className="min-w-0 ml-24 space-y-4">
                <div className="flex items-center leading-6">
                  {agencydata?.api_key}
                  <>
                    <Typography
                      className="text-md truncate"
                      color="text.secondary"
                    >
                      <span className="mx-8">&bull;</span>
                      <span className="font-medium">OnSign Api Key</span>
                    </Typography>
                  </>
                </div>
              </div>
            </div>
            {agencydata?.ibus_api_key && (
              <>
                <div className="flex">
                  <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {agencydata?.ibus_api_key}
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          <span className="mx-8">&bull;</span>
                          <span className="font-medium">IBus Api Key</span>
                        </Typography>
                      </>
                    </div>
                  </div>
                </div>
              </>
            )}
            <Divider textAlign="left" className="w-full mt-32">
              <Chip label="Agecny API's Feed" />
            </Divider>
            {agencydata?.provider_details ? (
              <Tooltip
                title={agencydata?.provider_details[0]?.stop_api}
                placement="top"
              >
                <div className="flex">
                  <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {agencydata?.provider_details[0]?.stop_api?.slice(0, 50) +
                        "..."}
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          <span className="mx-8">&bull;</span>
                          <span className="font-medium">Stop Api</span>
                        </Typography>
                      </>
                    </div>
                  </div>
                </div>
              </Tooltip>
            ) : (
              ""
            )}
            {agencydata?.provider?.name == "Avail" && (
              <>
                {agencydata?.provider_details ? (
                  <Tooltip
                    title={agencydata?.provider_details[0]?.public_api}
                    placement="top"
                  >
                    <div className="flex">
                      <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                      <div className="min-w-0 ml-24 space-y-4">
                        <div className="flex items-center leading-6">
                          <Typography>
                            {agencydata?.provider_details[0]?.public_api?.slice(
                              0,
                              50
                            ) + "..."}
                          </Typography>
                          <>
                            <Typography
                              className="text-md truncate"
                              color="text.secondary"
                            >
                              <span className="mx-8">&bull;</span>
                              <span className="font-medium">Public Api</span>
                            </Typography>
                          </>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
                {agencydata?.provider_details ? (
                  <Tooltip
                    title={agencydata?.provider_details[0]?.route_api}
                    placement="top"
                  >
                    <div className="flex">
                      <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                      <div className="min-w-0 ml-24 space-y-4">
                        <div className="flex items-center leading-6">
                          {agencydata?.provider_details[0]?.route_api?.slice(
                            0,
                            50
                          ) + "..."}
                          <>
                            <Typography
                              className="text-md truncate"
                              color="text.secondary"
                            >
                              <span className="mx-8">&bull;</span>
                              <span className="font-medium">Route Api</span>
                            </Typography>
                          </>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
                {agencydata?.provider_details ? (
                  <Tooltip
                    title={agencydata?.provider_details[0]?.vehicle_api}
                    placement="top"
                  >
                    <div className="flex">
                      <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                      <div className="min-w-0 ml-24 space-y-4">
                        <div className="flex items-center leading-6">
                          {agencydata?.provider_details[0]?.vehicle_api?.slice(
                            0,
                            50
                          ) + "..."}
                          <>
                            <Typography
                              className="text-md truncate"
                              color="text.secondary"
                            >
                              <span className="mx-8">&bull;</span>
                              <span className="font-medium">Vehicle Api</span>
                            </Typography>
                          </>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            )}
            <Divider textAlign="left" className="w-full mt-32">
              <Chip label="Agency Contacts" />
            </Divider>
            {agencydata?.contacts?.length > 0
              ? agencydata?.contacts?.map((val, index) => (
                  <div className="flex">
                    <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                    <div className="min-w-0 ml-24 space-y-8 my-4">
                      <Chip label={`Contact ${index + 1}`} />
                      <div className="flex items-center leading-6 my-6">
                        {val?.name}
                        <>
                          <Typography
                            className="text-md truncate"
                            color="text.secondary"
                          >
                            <span className="mx-8">&bull;</span>
                            <span className="font-medium">Name</span>
                          </Typography>
                        </>
                      </div>
                      <div className="flex items-center leading-6">
                        {val?.designation}
                        <>
                          <Typography
                            className="text-md truncate"
                            color="text.secondary"
                          >
                            <span className="mx-8">&bull;</span>
                            <span className="font-medium">Designation</span>
                          </Typography>
                        </>
                      </div>
                      <div className="flex items-center leading-6">
                        {val?.phone}
                        <>
                          <Typography
                            className="text-md truncate"
                            color="text.secondary"
                          >
                            <span className="mx-8">&bull;</span>
                            <span className="font-medium">Phone</span>
                          </Typography>
                        </>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyView;
